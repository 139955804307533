import React, { useEffect, useMemo, useState } from "react";

// for the wrapper/container
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  DragOverlay
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy
} from "@dnd-kit/sortable";

// for the individual
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


const View = (props) => {
  const [solved, setSolved] = useState(false);
  const [endType, setEndType] = useState();
  const [endValue, setEndValue] = useState();
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState([]);
  const itemIds = useMemo(() => items.map((item) => item.id), [items]);

  useEffect(() => {
    if(props.map && props.map.start.length > 0) {
      // create the start map objects
      let imageMap = [];
      props.map.start.forEach((img, idx) => {
        imageMap.push({ id: String(idx+1), value: img});
      });

      imageMap = shuffleArray(imageMap);
      setItems(imageMap);

      // // and the final message or image
      setEndType(props.map.endType);
      setEndValue(props.map.endValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check to see if they are in the right order
  useEffect(() => {
    if(itemIds.length > 0) {
      let inOrder = itemIds.every(function(num, index) {
        return index === itemIds.length - 1 || num < Number(itemIds[index + 1]);
      });

      setTimeout(() => {
        setSolved(inOrder);
      }, 1000)
    }
  }, [itemIds]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    setActiveId(null);
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(x => x.id === active.id);
        const newIndex = items.findIndex(x => x.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      { solved && (
        <p>Congratulations, you solved the puzzle!</p>
      )}

      {solved && endType==='image' && (
        <img src={endValue} className="img-fluid" alt={endValue} />
      )}

      {solved && endType==='text' && (
        <p>{endValue}</p>
      )}

      { !solved && (
        <p>Solve the puzzle by rearranging the map pieces below.</p>
      )}

      { !solved && (
        <div className="d-inline-block me-4 mb-4">
          <div className="d-flex flex-wrap">
            <SortableContext items={itemIds} strategy={rectSortingStrategy}>
              {items.map((obj) => (
                <SortableItem key={obj.id} id={obj.id} handle={true} value={obj.value} />
              ))}

              <DragOverlay>
                {activeId ? (
                  <div className="imagemap-item-active"></div>
                ) : null}
              </DragOverlay>
            </SortableContext>
          </div>
        </div>
      )}
    </DndContext>
  );
};

const SortableItem = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1
  };

  // if you want a handle, move listeners + attributes into that element

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style} className="imagemap-item">
      <img src={props.value} className="img-fluid" alt="Map Piece" />
    </div>
  );
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export default View;
