import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useForm } from 'react-hook-form'
import parse from 'html-react-parser'

import { Modal, Tab } from 'bootstrap'

import { checkFlags } from "../utils/flag-check.js";

import Material from "../components/Material";
import Action from "../components/Action";
import Communication from "../components/Communication";
import Messages from "../components/Messages";
import Newsfeed from "../components/Newsfeed";

const View = (props) => {
  const [bigError, showBigError] = useState(false);
  const socket = useRef(null);
  const gameId = useRef(null);
  const locationId = useRef(props.match.params.locationId);
  // const [simUrl, setSimUrl] = useState('https://expsim.s3.amazonaws.com/v2/disco/temp-vids/')
  const [simId, setSimId] = useState(null);
  const [styles, setStyles] = useState({
    navCol: "col-12 mb-3",
    nav: "nav nav-tabs",
    contentCol: "col-12"
  });
  const [simRoles, setSimRoles] = useState([]);
  const [gameObj, setGameObj] = useState(null);
  const gameRef = useRef({});
  const [gameTime, setGameTime] = useState(null);
  const [gameFlags, setGameFlags] = useState([]);
  const gameFlagRef = useRef([]);

  const [currentTab, setCurrentTab] = useState('role');
  const currentTabRef = useRef('role');

  const [connected, setConnected] = useState(false);
  const [authErr, setAuthErr] = useState(false);
  const [authed, setAuthed] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [roleObj, setRoleObj] = useState({});
  const roleRef = useRef({});

  const [newMsg, setNewMsg] = useState({});
  const [unreadMsgs, setUnreadMsgs] = useState(0);

  const [newsfeed, setNewsfeed] = useState([]);
  const [newFeedItems, setNewFeedItems] = useState(0);
  const newFeedItemsRef = useRef(0);

  const [activeActions, setActiveActions] = useState([]);
  const [newActions, setNewActions] = useState(0);
  const newActionsRef = useRef(0);

  const [activeComms, setActiveComms] = useState([]);
  const [newComms, setNewComms] = useState(0);
  const newCommRef = useRef(0);

  const { register, handleSubmit, clearErrors, reset } = useForm();

  const onSubmit = (data, e) => {
    console.log(data)
    e.target.reset();

    if(simId && (simId===2 || simId===4) && data.passcode && data.passcode==='27000')
      setAuthed(true);
    else if(simId && simId===11 && data.passcode && data.passcode==='1962')
      setAuthed(true);
    else {
      setAuthErr(true);
      setTimeout(() => {
        setAuthErr(false);
      }, 2000)
    }
  }

  // check localstorage to see if we have a role set for this tablet
  // lklklk: will need to also check if they're an admin...
  useEffect(() => {
    if(simId) {
      console.log('simId set, check for role')

      // get all roles for this sim
      axios.post('/api/v1/roles/all', { simId }).then(res => {
        if(res.data) setSimRoles(res.data)
      }).catch(error => {
        console.log(error)
      })

      // need to check if we have a role set for this simId
      // first check in the url, then check in local storage
      let paramRoleId = props.match.params.roleId;
      let localRoleId = localStorage.getItem('roleId['+simId+']');

      if(paramRoleId) {
        axios.post('/api/v1/roles/roleId', { shortCode: paramRoleId }).then(res => {
          if(res.data) {
            setRoleId(res.data.id);
          } else {
            setRoleError(true);
          }

        }).catch(error => {
          console.log(error)
          alert('Error finding role. Contact support.')
        })

      } else if(localRoleId)
        setRoleId(localRoleId);
      else {
        console.log('need to get roles for this sim')
        setRoleId(null);
      }
    }
  }, [simId, props.match.params.roleId])

  useEffect(() => {
    if(roleId) {
      console.log('getting role info: '+roleId)
      axios.post('/api/v1/roles/role', { roleId: roleId }).then(res => {
        if(res.data) {
          // combine and sort decisions + votes into one
          let roleData = res.data;
          let actions = [...roleData.decisions,...roleData.votes];
          actions.sort((a, b) => b.startTime - a.startTime);
          roleData.actions = actions;
          delete roleData.decisions;
          delete roleData.votes;

          // fix role contacts wtfff
          let fixedContacts = [];
          if(roleData.role?.roleContacts && Object.keys(roleData.role?.roleContacts).length > 0) {
            for (const value of Object.entries(roleData.role.roleContacts)) {
              fixedContacts.push(value);
            }
          }
          roleData.role.roleContacts = fixedContacts;

          roleRef.current = roleData;
          setRoleObj(roleData);
        }

      }).catch(error => {
        console.log(error)
        alert('Error getting data. Contact support.')
        showBigError(true);
      })
    }
  }, [roleId])

  // initiate socket
  useEffect(() => {
    if(!socket.current) socket.current = io.connect();

    socket.current.on('connect', () => {
      console.log('Connected to server')
      setConnected(true);
      socket.current.emit('join', {roomId: locationId.current, deviceType: 'tablet', deviceId: 'role_id_here?' });
    })

    socket.current.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
      setConnected(false);

      // set interval to refresh the page if we regain internet
      setInterval(() => {
        let internet = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (internet) window.location.reload(false);
      }, (1000 * 60 * 5));
    });

    socket.current.on('sysmsg', data => {
      console.log(data);
      if(data.type === 'refresh') window.location.reload(false);
      if(data.type === 'simId') {
        if(data.value!=='clear')
          setSimId(data.value)
        else
          setSimId(null)

        if(data.value===11)
          setStyles({
            navCol: "col-sm-3",
            nav: "nav nav-fill flex-column",
            contentCol: "col-sm-9"
          })

        // if(data.value===4)
        //   setSimUrl('https://expsim.s3.amazonaws.com/v2/disco/kal/')
        // else if(data.value===2)
        //   setSimUrl('https://expsim.s3.amazonaws.com/v2/disco/temp-vids/')
        // else
        //   setSimUrl('https://expsim.s3.amazonaws.com/cloud/files/')
      }

      if(data.type === 'gameObj') {
        setGameObj(data.value);
        gameRef.current = data.value;
        if(data.value && 'id' in data.value) gameId.current = data.value.id;
        if(data.value && 'flags' in data.value) {
          setGameFlags(data.value.flags);
          gameFlagRef.current = data.value.flags;
        }
        if(data.value && 'newsfeed' in data.value) {
          let newPosts = data.value.newsfeed.reverse();
          setNewsfeed(newPosts);
        }

        // if gameObj is null, reset tablet
        if(!data.value) {
          // reset flags
          setGameFlags([]);
          gameFlagRef.current = [];

          // reset to role tab
          let roleTab = new Tab(document.querySelector('#role-tab'));
          if(Object.keys(roleTab).length > 0) roleTab.show();

          setActiveActions([]);
          setNewActions(0);
          newActionsRef.current = 0;

          setActiveComms([]);
          setNewComms(0);
          newCommRef.current = 0;

          setNewFeedItems(0);
          newFeedItemsRef.current = 0;
        }
      }

      if(data.type === 'timer') {
        setGameTime(data.value);

        // check to see if the game has changed when the tablet was idle
        if(data.gameId && data.gameId!==gameId.current)
          window.location.reload(false);
      }

      if(data.type === 'flag') {
        let flag = data.value;
        // check if this flag exists in the state; if it does, remove + replace
        let found = gameFlagRef.current.findIndex(obj => obj.actionId === flag.actionId && obj.roleId === flag.roleId );
        if(found >= 0) gameFlagRef.current.splice(found, 1);
        gameFlagRef.current.push(flag);
        setGameFlags(gameFlagRef.current);
      }

      if(data.type === 'allFlags') {
        setGameFlags(data.value);
        gameFlagRef.current = data.value;
      }

      if(data.type === 'newsfeed') {
        let post = data.value;
        setNewsfeed((prevValue) => [post, ...prevValue]);
        newFeedItemsRef.current++;
        setNewFeedItems(newFeedItemsRef.current);
        let audio = new Audio("https://expsim.s3.amazonaws.com/v2/disco/sfx-comm.wav");
        audio.play();

        if(currentTabRef.current==='newsfeed') {
          // they're in the newsfeed, show but auto clear out
          setTimeout(() => {
            newFeedItemsRef.current = 0;
            setNewFeedItems(0);
          }, 2000);
        }
      }

      if(data.type === 'message') {
        let msg = data.value;
        let thisRoleId = roleRef.current?.role?.id;

        if(thisRoleId && msg.roleId !== thisRoleId) {
          setNewMsg(msg);
        }
      }
    });

    return () => {
      console.log('unmounting')
      socket.current.disconnect();
      socket.current = null;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(gameRef.current && Object.keys(gameRef.current).length > 0) {
      // COMMUNICATIONS
      let currentComms = [];
      let unreadComms = [];
      if(roleRef.current.comms && roleRef.current.comms.length > 0) {
        for (let i = 0; i < (roleRef.current.comms.length); i++) {
          // console.log(roleRef.current.comms[i])
          // console.log(gameRef.current)
          // first check if it's active
          let active = checkFlags(roleRef.current.comms[i], gameTime, gameRef.current.chapters[gameRef.current.currentChapter].id, gameRef.current.playedChapters, gameFlagRef.current);
          if(active) {
            currentComms.push(roleRef.current.comms[i]);

            // if it's older than 3 minutes OR from another chapter, auto-mark as read
            if(
              (gameTime - roleRef.current.comms[i].startTime) > 180 ||
              roleRef.current.comms[i].chapterId !== gameRef.current.chapters[gameRef.current.currentChapter].id
            )
              roleRef.current.comms[i].read = 'Y';

            // then check if it's unread
            if(roleRef.current.comms[i].read !=='Y')
              unreadComms.push(roleRef.current.comms[i]);
          }
        }

        setActiveComms(currentComms);
        setNewComms(unreadComms.length);

        if(unreadComms.length > newCommRef.current && unreadComms.length > 0) {
          let audio = new Audio("https://expsim.s3.amazonaws.com/v2/disco/sfx-comm.wav");
          audio.play();
        }
        newCommRef.current = unreadComms.length;
      }

      //ACTIONS
      let currentActions = [];
      if(roleRef.current.actions && roleRef.current.actions.length > 0) {
        for (let i = 0; i < (roleRef.current.actions.length); i++) {
          let action = roleRef.current.actions[i];

          // first check if it's active
          let active = checkFlags(action, gameTime, gameRef.current.chapters[gameRef.current.currentChapter].id, gameRef.current.playedChapters, gameRef.current.flags);
          if(!active) continue;

          let roleIdStr = String(roleRef.current.role.id);

          // check if this is assigned to this role, OR
          let assignedTo = action.assignedTo.list.includes(roleIdStr);
          let forwardTo = false;
          if(action.forwardTo && action.forwardTo.list && action.forwardTo.list.includes(roleIdStr))
            forwardTo = true;

          // check if the action was assigned in a recipient group
          // checking for both string and int values just in case
          roleRef.current.recipientGroups.forEach(group => {
            if(action.assignedTo.groups.includes(String(group.id)) ||
               action.assignedTo.groups.includes(parseInt(group.id)))
              assignedTo = true;
          });

          // if it's forwarded AND the original assignee is in inactiveRoles, OR
          let assignedToInactive = false;
          if(gameRef.current.inactiveRoles) {
            for (let x = 0; x < (action.assignedTo.list.length); x++) {
              if(gameRef.current.inactiveRoles.includes(action.assignedTo.list[x]))
                assignedToInactive = true;
            }
          }

          // console.log(active, assignedTo, forwardTo, assignedToInactive)

          if(assignedTo || (forwardTo && assignedToInactive)) {
            // check if executionType = Confirmed and if it's already in the log
            if(action.executionType === 'Confirmed') {
              // check if it's in the log, if it is NOT then add
              const found = gameFlags.filter(obj => obj.roleId===roleRef.current.role.id && obj.actionId===action.id);
              if(found.length === 0)
                currentActions.push(roleRef.current.actions[i]);

            } else {
              // action execution is full duration, auto-push
              currentActions.push(roleRef.current.actions[i]);
            }
          }
        }

        setActiveActions(currentActions);
        setNewActions(currentActions.length);
        if(currentActions.length > newActionsRef.current && currentActions.length > 0) {
          let audio = new Audio("https://expsim.s3.amazonaws.com/v2/disco/sfx-comm.wav");
          audio.play();
        }
        newActionsRef.current = currentActions.length;
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameTime, gameRef.current, roleRef.current])

  const showModal = (modalId, data) => {
    var modalEl = document.getElementById(modalId)
    var bsModal = Modal.getOrCreateInstance(modalEl)
    bsModal.show();
    modalEl.addEventListener('hide.bs.modal', modalHidden)
  }

  const hideModal = (modalId) => {
    var modalEl = document.getElementById(modalId)
    var bsModal = Modal.getOrCreateInstance(modalEl)
    bsModal.hide();
    modalEl.removeEventListener('hide.bs.modal', modalHidden)
  }

  const modalHidden = () => {
    reset();
    clearErrors();
    setAuthed(false);
  }

  // receives from child compoenents
  const fromChild = (data) => {
    console.log('received from child:',data)
    if(data.type === 'comm') {
      let key = roleRef.current.comms.findIndex(item => item.id === data.id);
      if (key !== null) {
        roleRef.current.comms[key].read = 'Y';
        setRoleObj(roleRef.current);

        newCommRef.current--;
        setNewComms(newCommRef.current);

      }
    } else if(data.type === 'unread messages') {
      setUnreadMsgs(data.value);
    }
  }

  const setActiveTab = (tab) => {
    setCurrentTab(tab);
    currentTabRef.current = tab;
    if(tab==='newsfeed') {
      setTimeout(() => {
        newFeedItemsRef.current = 0;
        setNewFeedItems(0);
      }, 2000);
    }
  }

  if(roleError) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100vh', backgroundColor:'#333', color:'#fff'}}>
        Error loading role. Contact support.<br />
        <button type="button" className="btn btn-outline-secondary" onClick={() => { window.location.reload(false); }}>Refresh Page</button>
      </div>
    )
  }

  if(bigError) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100vh', backgroundColor:'#333', color:'#fff'}}>
        Error loading content. Contact support.<br />
        <button type="button" className="btn btn-outline-secondary" onClick={() => { window.location.reload(false); }}>Refresh Page</button>
      </div>
    )
  }

  return (
    <>
      { (!simId && locationId.current==='Gq8LPlGt5Oih72X6DYTKD') && (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100vh', backgroundColor:'#122f52', color:'#fff'}}>
          <img src="https://expsim.s3.amazonaws.com/v2/disco/rrpfi-logo.png" style={{width:340}} alt="Reagan Foundation Logo" />
        </div>
      )}

      { (!simId && locationId.current!=='Gq8LPlGt5Oih72X6DYTKD') && (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '100vh', backgroundColor:'#333', color:'#fff'}}>
          ExpSim
        </div>
      )}

      { simId && !roleId && (
        <>
          <Helmet>
            <link rel="stylesheet" type="text/css" href="/styles/grenada.css" />
          </Helmet>

          <div className="container mb-5">
            <div className="row mt-5">
              <div className="col-sm-8 offset-sm-2">
                {simId===2 && (<h1>Grenada</h1>)}
                {simId===4 && (<h1>KAL</h1>)}
                {simId===11 && (<h1>Eyeball to Eyeball</h1>)}
                <h2>Select your role</h2>
                <div className="d-grid gap-3 mt-4">
                  <a className="btn btn-lg btn-outline-light" href={locationId.current+"/admin"} role="button">Admin</a>

                  {simRoles && simRoles.map((role, index) =>
                    <button key={ 'role-' + index.toString() } className="btn btn-lg btn-outline-light" type="button"
                      onClick={() => {
                        setRoleId(role.id)
                        localStorage.setItem('roleId['+simId+']', role.id);
                      }}>{role.epsName} {role.roleName}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      { simId && simId===2 && roleId && (
        <>
          <Helmet>
            <link rel="stylesheet" type="text/css" href="/styles/grenada.css" />
          </Helmet>

          <div className="container">
            <div className="row pt-4">
              <div id="header" className="col">
                { roleObj.role && roleObj.role.icon && (
                  <img src={roleObj.role.icon} className="role-icon change-role" alt="Role Icon" onClick={() => { showModal('roleModal') }} />
                )}

                { roleObj.role && roleObj.role.roleName && (<h1 onClick={() => { showModal('roleModal') }}>{roleObj.role.roleName}</h1>)}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <div className="card-wrapper">
                  { !gameObj && (
                    <p className="mb-0">Your actions will appear here.</p>
                  )}

                  { gameObj && activeActions.length===0 && (
                    <p className="mb-0">You have no actions at this time.</p>
                  )}

                  { activeActions.map((data,index) => {
                    return (<Action key={'action-'+index.toString()} item={data} roleObj={roleObj} locationId={locationId.current} gameObj={gameObj} gameTime={gameTime} gameFlags={gameFlags} socket={socket.current} roomId={locationId.current} />)
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      { (simId && (simId===4 || simId===11) && roleId) && (
        <>
          <Helmet>
            {/* need to split between KAL and E2E */}
            <link rel="stylesheet" type="text/css" href="/styles/kal.css" />
          </Helmet>

          <div className="container-fluid">
            <div className="row">
              <div id="header" className="col">

                <div className="mt-3 me-1 float-end">
                  { gameTime > 0 && (
                    <h2>{ new Date(gameTime * 1000).toISOString().substr(14, 5) }</h2>
                  )}
                </div>

                { roleObj.role && roleObj.role.icon && (
                  <img src={roleObj.role.icon} className="role-icon change-role" alt="Role Icon" onClick={() => { showModal('roleModal') }} />
                )}

                { roleObj.role && 'roleName' in roleObj.role && (<h1 onClick={() => { showModal('roleModal') }}>{roleObj.role.roleName}</h1>)}
                { roleObj.role && 'roleLocation' in roleObj.role && (<h3 onClick={() => { showModal('roleModal') }}>{roleObj.role.roleLocation}</h3>)}
              </div>
            </div>
            <div className="row mt-3">
              <div className={styles.navCol}>
                <ul className={styles.nav} id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="role-tab" data-bs-toggle="tab" data-bs-target="#role" type="button" role="tab" aria-controls="role" aria-selected="true"
                      onClick={()=>setActiveTab('role')}>
                      Role
                    </button>
                  </li>

                  { roleObj.role && roleObj.role.roleExplanation && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="explanation-tab" data-bs-toggle="tab" data-bs-target="#explanation" type="button" role="tab" aria-controls="explanation" aria-selected="true"
                        onClick={()=>setActiveTab('explanation')}>
                        Explanation
                      </button>
                    </li>
                  )}

                  { roleObj.materials && roleObj.materials.length > 0 && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="materials-tab" data-bs-toggle="tab" data-bs-target="#materials" type="button" role="tab" aria-controls="materials" aria-selected="true"
                        onClick={()=>setActiveTab('materials')}>
                        Materials
                      </button>
                    </li>
                  )}

                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="comms-tab" data-bs-toggle="tab" data-bs-target="#comms" type="button" role="tab" aria-controls="comms" aria-selected="true"
                      onClick={()=>setActiveTab('comms')}>
                      { newComms > 0 && (<span className="badge rounded-pill bg-warning text-dark">{newComms}</span>) } Communications
                    </button>
                  </li>

                  { roleObj.actions && roleObj.actions.length > 0 && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="actions-tab" data-bs-toggle="tab" data-bs-target="#actions" type="button" role="tab" aria-controls="actions" aria-selected="true"
                        onClick={()=>setActiveTab('actions')}>
                        { newActions > 0 && (<span className="badge rounded-pill bg-warning text-dark">{newActions}</span>) } Actions
                      </button>
                    </li>
                  )}

                  { roleObj.role?.roleContacts?.length > 0 && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="true"
                        onClick={()=>setActiveTab('messages')}>
                        { unreadMsgs > 0 && (<span className="badge rounded-pill bg-warning text-dark">{unreadMsgs}</span>) } Messages
                      </button>
                    </li>
                  )}

                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="newsfeed-tab" data-bs-toggle="tab" data-bs-target="#newsfeed" type="button" role="tab" aria-controls="newsfeed" aria-selected="true"
                      onClick={()=>setActiveTab('newsfeed')}>
                      { newFeedItems > 0 && (<span className="badge rounded-pill bg-warning text-dark">{newFeedItems}</span>) } Newsfeed
                    </button>
                  </li>

                  { simId===11 && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="cast-tab" data-bs-toggle="tab" data-bs-target="#cast" type="button" role="tab" aria-controls="cast" aria-selected="true"
                        onClick={()=>setActiveTab('cast')}>
                        Cast
                      </button>
                    </li>
                  )}
                </ul>
              </div>
              <div className={styles.contentCol}>
                <div className="tab-content">
                  <div className="tab-pane active" id="role" role="tabpanel" aria-labelledby="role-tab">
                    { roleObj.role && 'roleContent' in roleObj.role && ( parse(roleObj.role.roleContent) )}
                  </div>
                  <div className="tab-pane" id="explanation" role="tabpanel" aria-labelledby="explanation-tab">
                    { roleObj.role && roleObj.role.roleExplanation && ( parse(roleObj.role.roleExplanation) )}
                  </div>
                  <div className="tab-pane" id="materials" role="tabpanel" aria-labelledby="materials-tab">
                    { !gameObj && (
                      <p className="mb-0">Your primary sources will appear here.</p>
                    )}

                    { gameObj && gameObj.status && roleObj.materials && roleObj.materials.map((data,index) => (
                      <Material key={`material-${data.id}`} material={data} />
                    ))}
                  </div>
                  <div className="tab-pane" id="comms" role="tabpanel" aria-labelledby="comms-tab">
                    { !gameObj && (
                      <p className="mb-0">Your communications will appear here.</p>
                    )}

                    { gameObj && activeComms.length===0 && (
                      <p className="mb-0">You have no communications at this time.</p>
                    )}

                    { activeComms.map((data,index) => {
                      return (<Communication key={'comm-'+data.id.toString()} item={data} fromChild={fromChild} />)
                    })}
                  </div>
                  <div className="tab-pane" id="actions" role="tabpanel" aria-labelledby="actions-tab">
                    { !gameObj && (
                      <p className="mb-0">Your actions will appear here.</p>
                    )}

                    { gameObj && activeActions.length===0 && (
                      <p className="mb-0">You have no actions at this time.</p>
                    )}

                    { activeActions.map((data,index) => {
                      return (<Action key={'action-'+data.id} item={data} roleObj={roleObj} locationId={locationId.current} gameObj={gameObj} gameTime={gameTime} gameFlags={gameFlags} socket={socket.current} roomId={locationId.current} />)
                    })}
                  </div>
                  <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                    { !gameObj && (
                      <p className="mb-0">Your messages will appear here.</p>
                    )}

                    { gameObj && (
                      <Messages currentTab={currentTab} newMsg={newMsg} simRoles={simRoles} role={roleObj.role} socket={socket.current} locationId={locationId.current} gameId={gameObj.id} inactive={gameRef.current?.inactiveRoles} toParent={fromChild} />
                    )}
                  </div>
                  <div className="tab-pane" id="newsfeed" role="tabpanel" aria-labelledby="newsfeed-tab">
                    { gameObj && roleObj?.role?.newsfeedPost==='Y' && (
                      <Newsfeed roleObj={roleObj} socket={socket.current} gameObj={gameObj} roomId={locationId.current} />
                    )}

                    {(!newsfeed || newsfeed.length===0) && (
                      <p className="mb-0">The newsfeed will appear here.</p>
                    )}

                    {newsfeed && newsfeed.map((post, index) =>
                      <div key={ 'posts-' + index.toString() }>
                        {parse(post.content)}
                        { post.postedBy && (
                          <p className="small mt-0 fst-italic">{post.postedBy}</p>
                        )}
                        <hr />
                      </div>
                    )}

                  </div>

                  <div className="tab-pane" id="cast" role="tabpanel" aria-labelledby="cast-tab">
                    {simRoles && simRoles.map((role, index) =>
                      <div className="row" key={ 'roles-' + index.toString() }>
                        <div className="col-3">
                          {role.icon && (
                            <img src={role.icon} className="img-fluid border" alt="Role Icon" />
                          )}
                        </div>
                        <div className="col-9">
                          <h4>{role.roleName}</h4>
                          {parse(role.roleDescription)}
                        </div>
                        <hr />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="modal fade" id="roleModal" tabIndex="-1" aria-labelledby="roleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="roleModalLabel">
                Change Role
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

              { authed && (
                <>
                <div className="modal-body text-center">
                  <div className="d-grid gap-3 mt-4">
                    {simRoles && simRoles.map((role, index) =>
                      <button key={ 'role-' + index.toString() } className="btn btn-lg btn-outline-primary" type="button"
                        onClick={() => {
                          setRoleId(role.id)
                          localStorage.setItem('roleId['+simId+']', role.id);
                          hideModal('roleModal');
                        }}>{role.epsName} {role.roleName}</button>
                    )}
                    <a className="btn btn-lg btn-outline-primary" href={locationId.current+"/admin"} role="button">Admin/Proctor</a>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
                </>
              )}

              { !authed && (
                <form onSubmit={handleSubmit(onSubmit)} className="form" encType="multipart/form-data" autoComplete="off">
                  <div className="modal-body text-center">
                    <input {...register("passcode")} className="form-control form-control-lg" type="number" placeholder="Enter passcode" />
                    { authErr && (
                      <p className="mt-2 text-danger small">Incorrect passcode.</p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-outline-secondary" onClick={() => { window.location.reload(false); }}>Refresh Page</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  </div>
                </form>
              )}
          </div>
        </div>
      </div>

      { !connected && (<div id="connection"></div>)}
    </>
  );
}

export default View;
