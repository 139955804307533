import React, { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const View = (props) => {
  const socket = props.socket;
  const locationId = props.locationId;
  const gameId = props.gameId;
  const simRoles = props.simRoles;
  const inactiveRoles = props.inactive;
  const role = props.role;
  const [mailbox, setMailbox] = useState();
  const [messages, setMessages] = useState([]);
  const totalUnreadRef = useRef(0);
  // and how do we send up total unread to ...?
  const mailboxRef = useRef();

  const newMsg = props.newMsg;
  const currentTab = props.currentTab;

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  useEffect(() => {
    console.log('first message load');
    if(role && role.id) {
      axios.post('/api/v1/messages/all', { roleId: role.id, gameId }).then(res => {
        if(res.data && res.data.length > 0) {
          res.data.forEach((msg, idx) => {
            let timestamp = dayjs(msg.timestamp, 'MMM D, YYYY h:mma');
            let timeago = dayjs(timestamp).fromNow(true);
            let secondsAgo = dayjs().diff(timestamp, 'second');
            res.data[idx].timeago = timeago;
            res.data[idx].unread  = (msg.recipientId===role.id && secondsAgo < 180) ? 'Y' : 'N';
          })

          let unread = res.data.filter(x => x.unread === 'Y');
          totalUnreadRef.current = unread.length;
          props.toParent({type: 'unread messages', value: totalUnreadRef.current});
          setMessages(res.data);
        }
      }).catch(error => {
        console.log(error)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    if(Object.keys(newMsg).length > 0) {
      if(currentTab!=='messages' || (mailbox && newMsg.roleId !== mailbox.id)) {
        // only mark it as unread if we aren't currently in that mailbox
        newMsg.unread = 'Y';
        totalUnreadRef.current++;
        props.toParent({type: 'unread messages', value: totalUnreadRef.current});
      } else {
        newMsg.unread = 'N';
      }

      let newMsgs = [...messages];
      newMsgs.unshift(newMsg);
      setMessages(newMsgs);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMsg])

  useEffect(() => {
    // re-open mailbox so we clear out unread messages
    if(currentTab==='messages' && mailbox?.id)
      openMailbox(mailbox.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const openMailbox = (id) => {
    let contact = simRoles.find(x => x.id === id);
    if(contact) {
      setMailbox(contact);
      mailboxRef.current = contact;

      setTimeout(() => {
        let newMsgs = [...messages];
        newMsgs.forEach((msg, idx) => {
          if(msg.roleId === contact.id && msg.unread==='Y') {
            newMsgs[idx].unread = 'N';
            totalUnreadRef.current--;
          }
        });

        setMessages(newMsgs);
        props.toParent({type: 'unread messages', value: totalUnreadRef.current});

      }, 1000);
    }
  }

  const onSubmit = (data) => {
    let newMsg = {
      simId: 4,
      gameId: gameId,
      roleId: role.id,
      recipientId: mailboxRef.current.id,
      message: data.message,
      timestamp: dayjs().format('MMM D, YYYY h:mma')
    }

    let newMsgs = [...messages];
    newMsgs.unshift(newMsg);
    setMessages(newMsgs);

    axios.post('/api/v1/messages/new', newMsg).then(res => {
      // console.log(res);
    }).catch(error => {
      console.log(error);
    })

    socket.emit('clientmsg', { roomId: locationId, gameId: gameId, message: newMsg });

    reset();
  }

  return (
    <div className="row">
      <div className="col-sm-3">
        <div className="sticky-top pt-2">
          <ul className="list-group">
            { role && role.roleContacts?.length > 0 && role.roleContacts?.map((obj, idx) => {
              let contact = simRoles.find(x => x.id === Number(obj));
              if(!contact) return (null);

              // check to see if this role is inactive
              if(inactiveRoles?.includes(obj)) return (null);

              // otherwise grab the number unread and display
              let unread = messages.filter(x => x.unread === 'Y' && x.roleId === contact.id);

              return (
                <li key={'mailbox-'+contact.id} className="list-group-item text-wrap pointer" onClick={()=>openMailbox(contact.id)}>
                  {contact.roleName}
                  { unread.length > 0 && (<span className="badge rounded-pill bg-warning text-dark float-end">{unread.length}</span>) }
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="col-sm-9 mb-5 pb-5">
        { !mailbox && (
          <p>Click a name to the left to view a conversation.</p>
        )}

        { mailbox && (
          <Fragment>
            <h3 className="sticky-top bg-white pt-2">{mailbox.roleName}</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="form mb-4" encType="multipart/form-data" autoComplete="off">
              <textarea {...register("message", {required: 'tbd'})} className="form-control form-control-lg"></textarea>
              {errors.message && <p className="small text-danger mb-0">Please enter a message</p>}

              <button type="submit" className="btn btn-primary mt-2 me-2">Submit</button>
              <button type="button" className="btn btn-outline-secondary mt-2" onClick={() => reset()}>Clear</button>
            </form>

            { messages.filter(x => x.roleId === mailbox.id || x.recipientId === mailbox.id).map((msg, idx) => {
              let timestamp = dayjs(msg.timestamp, 'MMM D, YYYY h:mma');
              let timeago = dayjs(timestamp).fromNow();

              return (
                <div key={'msg-'+idx} className="border-top pt-2 pb-3">
                  {nl2br(msg.message)}
                  { msg.roleId === role.id && (<p className="small text-muted">from you, {timeago}</p>)}
                  { msg.roleId !== role.id && (<p className="small text-muted">from {mailbox.roleName}, {timeago}</p>)}
                </div>
              )
            })}

          </Fragment>
        )}

        <button type="button"
          className="btn btn-primary"
          style={{position:'fixed',right:22,bottom:22}}
          onClick={()=> window.scrollTo(0, 0)}>
          Back to Top
        </button>
      </div>
    </div>
  );
}

const nl2br = (str, is_xhtml) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export default View;
