import React from 'react';
import parse from 'html-react-parser';


import MemoryGame from "./MemoryGame";
import Anagram from "./Anagram";
import ImageMap from "./ImageMap";

const View = (props) => {
  const debug = false;
  const socket = props.socket;
  const locationId = props.locationId;
  const gameObj = props.gameObj;
  const gameFlags = props.gameFlags;
  const gameTime = props.gameTime;
  const roleObj = props.roleObj;
  const item = props.item;

  const fileFlag = (flag) => {
    console.log('file new flag: ',flag);
    console.log('gameId:',gameObj.id,)
    socket.emit('clientmsg', { roomId: locationId, gameId: gameObj.id, flag });
  }

  let timeRemaining = item.endTime - gameTime;
  timeRemaining = new Date(timeRemaining * 1000).toISOString().substr(14, 5)

  if(item?.template==='anagram') {
    let content = item.content.replace('<p>', '');
    content = content.replace('</p>', '');
    content = JSON.parse(content);

    return (
      <div>
        <div className="float-end">{timeRemaining}</div>
        <p>Solve the sentence by rearranging the below letters.</p>
        {content.map((word, key) => (
          <Anagram key={`anagram${key}`} word={word} />
        ))}
      </div>
    );

  } else if(item?.template==='image-map') {
    let imageMap = item?.puzzle?.replace('<p>', '');
    imageMap = imageMap.replace('</p>', '');

    try {
      imageMap = JSON.parse(imageMap);
    } catch(e) {
      imageMap = {start:[],endType:null,endValue:null};
    }

    return (
      <div>
        <div className="float-end">{timeRemaining}</div>
        <ImageMap map={imageMap} />
      </div>
    )

  } else if(item?.template==='memory') {
    let game = item?.puzzle?.replace('<p>', '');
    game = game.replace('</p>', '');

    try {
      game = JSON.parse(game);
    } catch(e) {
      game = {cards:[],cardBack:null,endType:null,endValue:null};
    }

    return (
      <div>
        <div className="float-end">{timeRemaining}</div>
        <MemoryGame game={game} />
      </div>
    )
  } else {
    // remove action items; will need to figure out how to replace it with optionHtml later
    let content = item.content.replace('<p><span>Action Options</span><br /></p>', '');
    content = content.replace('<p><span data-redactor-type="variable">Action Options</span></p>', '');
    content = content.replace('<span data-redactor-type="variable">Action Options</span>', '');

    let optionHtml = item.options.filter(obj => obj['option-text'] && obj['option-flag']).map((opt,idx) => {
      let activeBtn = '';
      // it's only active for them if THEY have selected
      let foundFlags = gameFlags.filter(function(e) {
        return (e.value===opt['option-flag'] && e.roleId===roleObj.role.id);
      });

      if(foundFlags.length > 0)
        activeBtn = 'active';

      // lklklk add disabled if it's paused?

      return (
        <button key={ 'option-'+item.id+'-' + idx.toString() } className={"btn btn-lg btn-primary d-block mx-auto mb-3 " + activeBtn}
          onClick={() => { fileFlag({roleId: parseInt(roleObj.role.id), actionId: item.id, value: opt['option-flag']}) }}>
            {parse(opt['option-text'])}
        </button>
      )
    })

    return (
      <>
        <div className="float-end">{timeRemaining}</div>
        { debug && ( <strong>{item.id} - {item.title} - {item.startTime} - {item.endTime} - {item.flagType} - {item.flag}</strong> )}
        {parse(content)}
        {optionHtml}
      </>
    )
  }
}

export default View;
