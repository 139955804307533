import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { Helmet } from 'react-helmet';

// https://stackoverflow.com/questions/51734372/how-to-prefetch-video-in-a-react-application



// dummy u need to add the flags back in
// so you can do the vote screens easily



const Video = (props, onVideoEnd) => {
  const videoRef = useRef();
  const displaying = props.display;
  const playSpeed = useRef(props.playSpeed);
  const videoPause = useRef(props.videoPause);

  useEffect(() => {
    console.log('loaded')
    // set playback speed
    videoRef.current.playbackRate = playSpeed.current;

    // set volume, or get override from playlist
    if(!displaying.muted) videoRef.current.volume = 0.8;
    videoRef.current.volume = (displaying.volume) ? displaying.volume : 0.8;

    return () => { console.log('video component unmounting') };
  }, [displaying]);

  useEffect(() => {
    console.log('here',videoPause.current);

    if(videoPause.current)
      videoRef.current.pause();
    else
      videoRef.current.play();

  }, [videoPause])

  // lklklk you need to pass the video ended back up to the parent
  // or figure out a way to show the default from here
  // then work on the pausing.

  const videoEnded = () => {
    console.log('video ended')
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    // onVideoEnd();
  }

  return (
    <video ref={videoRef} muted={displaying.muted} loop={displaying.loop} onEnded={() => videoEnded()} autoPlay crossOrigin="anonymous" preload="auto"
      style={{width:props.width, height:props.height, objectFit: 'cover'}}>
      <source src={props.simUrl+displaying.source} type={'video/'+displaying.type}></source>
      { displaying.captions && (<track kind="subtitles" src={props.simUrl+displaying.captions} srcLang="en" default />)}
    </video>
  )
}

const View = (props) => {
  const locationId = useRef(null);
  const displayUuid = useRef(props.match.params.displayId);
  const displayId = useRef(null);
  // const displayWidth = useRef(600);
  // const displayHeight = useRef(400);
  const displayWidth = useRef(1920);
  const displayHeight= useRef(1080);
  const [displaying, setDisplaying] = useState(null);
  // const videoPause = useRef(false);
  const [videoPause, setVideoPause] = useState(false);
  const playSpeedRef = useRef(1);
  const socket = useRef(null);
  const [connected, setConnected] = useState(false);
  const [simId, setSimId] = useState(null);
  const [simUrl, setSimUrl] = useState('https://expsim.s3.amazonaws.com/v2/disco/temp-vids/')
  const [gameFlags, setGameFlags] = useState([]);
  const gameFlagRef = useRef([]);

  // other things we receive, commenting but saving in case you need them later
  // const [gameStatus, setGameStatus] = useState(null);
  // const [gameTime, setGameTime] = useState(null);

  useEffect(() => {
    // this is where we would hit the db to get the location record info
    // based on the display info. for today we are just hard-coding
    // because you are RUNNING OUT OF TIME.
    locationId.current = 'Gq8LPlGt5Oih72X6DYTKD';

    if(displayUuid.current === '46682c11-cd83-452c-b8c8-2d2e6bec5998') displayId.current = 'LB-01';
    if(displayUuid.current === '2150ec90-74ed-40ea-8541-cb372dd17377') displayId.current = 'LB-02';
    if(displayUuid.current === '95091ebe-e2d8-4f6c-9743-16307efc82a0') displayId.current = 'LB-03';

    // // hardcode lobby tv sizes
    // if(displayUuid.current === '46682c11-cd83-452c-b8c8-2d2e6bec5998' ||
    //    displayUuid.current === '2150ec90-74ed-40ea-8541-cb372dd17377' ||
    //    displayUuid.current === '95091ebe-e2d8-4f6c-9743-16307efc82a0') {
    //   displayWidth.current = 1024;
    //   displayHeight.current = 786;
    // }


    if(displayUuid.current === 'b6f7dd46-917e-477d-ab4e-e6b38b986b32') displayId.current = 'CDC-01';
    if(displayUuid.current === 'cbfc6ae8-b767-402d-8c52-831f853fd5c9') displayId.current = 'CDC-02';
    if(displayUuid.current === 'e26ea600-2572-4bba-a331-46d4c34f8b7b') displayId.current = 'CDC-03';
    if(displayUuid.current === '6f77c212-9264-4ac9-b56b-7a80d8cd980d') displayId.current = 'CDC-04';
    if(displayUuid.current === '8e624d52-b5d7-405a-b627-4335c149d2af') displayId.current = 'CDC-05';
    if(displayUuid.current === '106e9ed0-8c0e-4132-8f34-12f7b80367e4') displayId.current = 'CDC-06';
    if(displayUuid.current === 'b26ce35e-8b41-445b-86c0-cfacffa3503e') displayId.current = 'CDC-07';
    if(displayUuid.current === '15fa6b09-52a9-4b49-a058-35ea2f684613') displayId.current = 'CDC-08';
    if(displayUuid.current === '2b023504-0cd0-4322-b038-f2a2dc7cb614') displayId.current = 'CDC-09';
    if(displayUuid.current === 'c3c67dca-5897-4052-a286-b95e90dcc595') displayId.current = 'CDC-10';
    if(displayUuid.current === 'd8644350-406e-42b7-8ee6-3471aea42b0c') {
      displayId.current = 'OO-01';
      displayWidth.current = 3840;
      displayHeight.current = 2160;
    }
    if(displayUuid.current === 'ec9a1aa2-823b-4ace-9b04-3a62c61632c9') displayId.current = 'OO-02';
    if(displayUuid.current === '9c7df9b0-8f43-4414-85c5-1d5e09b8ed1a') {
      displayId.current = 'PR-01';
      displayWidth.current = 3840;
      displayHeight.current = 2160;
    }
    if(displayUuid.current === '371480b0-7636-47fd-8c97-c623493d9a28') displayId.current = 'PR-02';
    if(displayUuid.current === '712ca61a-568f-4434-b351-158dcc436a0a') displayId.current = 'AFO-01';

  }, [])

  // initiate socket
  useEffect(() => {
    if(!socket.current) socket.current = io.connect();

    socket.current.on('connect', () => {
      console.log('Connected to server')
      setConnected(true);
      // lklklk testing for deviceId so you can store it later
      // lb.01 shoudl be switched to displayId.current
      socket.current.emit('join', {roomId: locationId.current, deviceType: 'display', deviceId: 'LB.01' });
    })

    socket.current.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
      setConnected(false);

      // set interval to refresh the page if we regain internet
      setInterval(() => {
        let internet = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (internet) window.location.reload(false);
      }, (1000 * 60 * 5));
    });

    socket.current.on('sysmsg', data => {
      console.log(data)
      if(data.type === 'refresh') window.location.reload(false);
      // if(data.type === 'timer')   setGameTime(data.value);
      if(data.type === 'gameObj' && data.value && 'playSpeed' in data.value) playSpeedRef.current = data.value.playSpeed;
      if(data.type === 'simId') {
        setSimId(data.value)
        if(data.value===4)
          setSimUrl('https://expsim.s3.amazonaws.com/v2/disco/kal/')
        else
          setSimUrl('https://expsim.s3.amazonaws.com/v2/disco/temp-vids/')
      }

      if(data.type === 'gameStatus') {
        let newStatus = data.value;
        // setGameStatus(newStatus);
        // if we aren't in a game, clear out the flags
        if(!newStatus) {
          gameFlagRef.current = [];
          setGameFlags([]);
        }
      }

      if(data.type === 'flag') {
        let flag = data.value;
        // check if this flag exists in the state; if it does, remove + replace
        let found = gameFlagRef.current.findIndex(obj => obj.actionId === flag.actionId && obj.roleId === flag.roleId );
        if(found >= 0) gameFlagRef.current.splice(found, 1);
        gameFlagRef.current.push(flag);
        setGameFlags(gameFlagRef.current);
      }

      if(data.type === 'display') {
        let display = data.value;
        if(!display) {
          setDisplaying(null)
        } else if(display.id === displayId.current) {
          if(display.template==='default')
            setDisplaying(null)
          else
            setDisplaying(display)
        }
      }

      // video controls
      // if(data.type === 'pause display') videoPause.current = true;
      // if(data.type === 'resume display') videoPause.current = false;
      if(data.type === 'pause display') setVideoPause(true);
      if(data.type === 'resume display') setVideoPause(false);
    });

    return () => {
      console.log('unmounting')
      socket.current.disconnect();
      socket.current = null;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVideoEnd = () => {
    console.log('received video ended from child, nothing else is playing')
    setDisplaying(null);
    // if you need this later...
    // if(videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2) {
    //   console.log('something is playing');
    // }
  }

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          body {
            width: `+displayWidth.current+`px;
            height: `+displayHeight.current+`px;
            background-color: #122f52;
            color: white;
			      overflow:hidden;
            cursor: none;
          }
        `}</style>
      </Helmet>

      <div style={{width:displayWidth.current, height: displayHeight.current, position: 'relative'}}>

        { !simId && (
          <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
            <img src="https://expsim.s3.amazonaws.com/v2/disco/rrpfi-logo.png" style={{width:300}} alt="Reagan Foundation Logo" />
          </div>
        )}

        { simId === 2 && (
          <>
            { !displaying && displayId.current && displayId.current.includes('CDC') && (
              <img src={simUrl+'metal-graphic.jpg'} alt="Metal Grid" style={{width:displayWidth.current, height: displayHeight.current, objectFit: 'cover'}} />
            )}

            { !displaying && displayId.current && !displayId.current.includes('CDC') && (
              <img src={simUrl+'presidential-seal.jpg'} alt="Presidential Seal" style={{width:displayWidth.current, height: displayHeight.current, objectFit: 'cover'}} />
            )}
          </>
        )}

        { !displaying && simId === 4 && (
          <img src={simUrl+'kal-seal.jpg'} alt="KAL Presidential Seal" style={{width:displayWidth.current, height: displayHeight.current, objectFit: 'cover'}} />
        )}

        { displaying && displaying.template===1 && (
          <Video key={displaying.source} videoPause={videoPause} simUrl={simUrl} width={displayWidth.current} height={displayHeight.current} display={displaying} playSpeed={playSpeedRef.current} onVideoEnd={onVideoEnd} />
        )}

        { displaying && displaying.template===2 && (
          <img src={simUrl+displaying.source}
            alt="Displayed Content" style={{width:displayWidth.current, height: displayHeight.current, objectFit: 'cover'}} />
        )}

        { displaying && displaying.template===3 && (
          <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
            Voting Screen Here

            <ul>
              {gameFlags && gameFlags.map((flag, index) =>
                <li key={ 'flag-' + index.toString() }>{flag.value}</li>
              )}
            </ul>
          </div>
        )}

        { !connected && (<div id="connection"></div>)}
      </div>
    </>
  );
}

export default View;

// LK note: you can add +'#t=10' to the video source to fast forward it
// will need to do that for the countdowns

// if you need to explore downloading:
// https://www.google.com/search?q=dexie+js+download+large+files&biw=1159&bih=589&sxsrf=AOaemvJrDceWINjlixhSFRRjZk1Hm_z96w%3A1636772432721&ei=UCqPYdvJK6Xt9APrm6aoBg&oq=dexie+js+download+large+files&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCrAjIFCCEQqwI6BwgAEEcQsAM6CAghEBYQHRAeOgcIIRAKEKABSgQIQRgAUI0IWOMXYNcYaAZwAngAgAGXAYgBgAuSAQQzLjEwmAEAoAEByAEIwAEB&sclient=gws-wiz&ved=0ahUKEwjbsbi7rJT0AhWlNn0KHeuNCWU4ChDh1QMIDw&uact=5
// https://stackoverflow.com/questions/30392426/html5-video-from-chunks-saved-in-indexeddb-chrome-for-android
